// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SkeletonLoader_skeleton__V\\+i72 {\n  border-radius: var(--radius-primary);\n}\n\n.SkeletonLoader_medium__ixI0e {\n  min-height: var(--height-small);\n}\n@media only screen and (min-width: 640px) {\n  .SkeletonLoader_medium__ixI0e {\n    min-height: var(--height-medium);\n  }\n}\n\n.SkeletonLoader_small__hsDDU {\n  min-height: var(--height-small);\n}", "",{"version":3,"sources":["webpack://./src/components/SkeletonLoader/SkeletonLoader.module.scss","webpack://./src/styles/_mixin.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;AADF;;AAIA;EACE,+BAAA;AADF;ACOE;EDPF;IAII,gCAAA;EAAF;AACF;;AAGA;EACE,+BAAA;AAAF","sourcesContent":["@use 'src/styles/mixin' as mixin;\n\n.skeleton {\n  border-radius: var(--radius-primary);\n}\n\n.medium {\n  min-height: var(--height-small);\n\n  @include mixin.tablet {\n    min-height: var(--height-medium);\n  }\n}\n\n.small {\n  min-height: var(--height-small);\n}\n","$breakpoint-xs: 0px;\n$breakpoint-sm: 640px;\n$breakpoint-md: 1024px;\n$breakpoint-lg: 1280px;\n$breakpoint-xl: 1440px;\n\n@mixin phone {\n  @media only screen and (min-width: $breakpoint-xs) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media only screen and (min-width: $breakpoint-sm) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media only screen and (min-width: $breakpoint-md) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media only screen and (min-width: $breakpoint-lg) {\n    @content;\n  }\n}\n\n@mixin bigScreen {\n  @media only screen and (min-width: $breakpoint-xl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "SkeletonLoader_skeleton__V+i72",
	"medium": "SkeletonLoader_medium__ixI0e",
	"small": "SkeletonLoader_small__hsDDU"
};
export default ___CSS_LOADER_EXPORT___;
