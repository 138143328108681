import useSWR from 'swr'

import { SwrKey } from 'assets/const'
import { UserService } from 'services/user.service'
import {useSseStore} from '../../store/useSseStore';

export const useUserProfile = () => {
  const user = useSWR(SwrKey.User, () => UserService.getProfile(), {
    errorRetryCount: 0,
    revalidateOnFocus: false,
  })

  return {
    ...user,
  }
}
