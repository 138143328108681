// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserInfo_container__h11eT {\n  display: grid;\n}\n.UserInfo_container__h11eT .UserInfo_avatar__yl2xN {\n  grid-row: 1/span 2;\n  place-self: center;\n  margin-right: 14px;\n}\n.UserInfo_container__h11eT .UserInfo_name__LFv7a {\n  display: flex;\n  gap: 0.1em;\n  grid-column: 2/3;\n  margin-left: 2px;\n}\n.UserInfo_container__h11eT .UserInfo_email__FBMnD {\n  margin-left: 2px;\n}", "",{"version":3,"sources":["webpack://./src/components/UserInfo/UserInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,kBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACE;EACE,aAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,gBAAA;AACJ","sourcesContent":[".container {\n  display: grid;\n\n  .avatar {\n    grid-row: 1 / span 2;\n    place-self: center;\n    margin-right: 14px;\n  }\n  .name {\n    display: flex;\n    gap: 0.1em;\n    grid-column: 2/3;\n    margin-left: 2px;\n  }\n  .email {\n    margin-left: 2px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UserInfo_container__h11eT",
	"avatar": "UserInfo_avatar__yl2xN",
	"name": "UserInfo_name__LFv7a",
	"email": "UserInfo_email__FBMnD"
};
export default ___CSS_LOADER_EXPORT___;
