// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaperHeader_paperHeader__vFE3a {\n  box-sizing: border-box;\n  display: flex;\n  width: 100%;\n  align-items: center;\n  overflow: hidden;\n  border-top-left-radius: inherit;\n  border-top-right-radius: inherit;\n  margin-bottom: 24px;\n}\n.PaperHeader_paperHeader__vFE3a > h1 {\n  font-weight: 500;\n  font-size: 24px;\n  line-height: 1.3;\n}\n@media (max-width: 640px) {\n  .PaperHeader_paperHeader__vFE3a > h1 {\n    font-size: 18px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/PaperHeader/PaperHeader.module.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,+BAAA;EACA,gCAAA;EACA,mBAAA;AADF;AAGE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAGI;EALF;IAMI,eAAA;EAAJ;AACF","sourcesContent":["@use 'src/styles/mixin' as mixin;\n\n.paperHeader {\n  box-sizing: border-box;\n  display: flex;\n  width: 100%;\n  align-items: center;\n  overflow: hidden;\n  border-top-left-radius: inherit;\n  border-top-right-radius: inherit;\n  margin-bottom: 24px;\n\n  & > h1 {\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 1.3;\n\n    @media (max-width: 640px) {\n      font-size: 18px;\n    }\n    // @include mixin.laptop {\n    //   font-size: 16px;\n    //   line-height: 22px;\n    // }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paperHeader": "PaperHeader_paperHeader__vFE3a"
};
export default ___CSS_LOADER_EXPORT___;
