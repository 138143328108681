import { api } from '../api/api'
import {
    MangoCardRequest,
    MangoDictionary,
    MangoKYCRequest,
    PayinMangoCardRequest
} from '../types/mango';
import {uuid} from '../utils/uuid';
import {SelectOption} from '../../src/components/CustomInputSelect';

const prepareMangoDictionaryData = (arr: []): SelectOption[] => {
    return arr.map(item => {
        return {
            label: item,
            value: item
        }
    })
}
export const transformMangoDictionary = (data: any) :MangoDictionary => {
 return {
     clientId: data.clientId,
     cardTypeList: prepareMangoDictionaryData(data.cardTypeList),
     currencyList: prepareMangoDictionaryData(data.currencyList),
 }
}
export const MangoService = {
    async getDict(): Promise<MangoDictionary> {
        return api(`mango/dict`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
                    .then(data => transformMangoDictionary(data))
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async createMangoPayer() {
        return api('mango/payer/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async createMangoCard(payload: MangoCardRequest) {
        return api('mango/card/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...payload, sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async payinMangoCard(payload: PayinMangoCardRequest) {
        return api('mango/card/payin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...payload, sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async getMangoPaymentInfo(invoiceId: string) {
        return api(`mango/payment/info?invoiceId=${invoiceId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    // --------kyc area------
    async createKYC() {
        return api(`mango/kyc/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async pageKYC(data: MangoKYCRequest) {
        return api(`mango/kyc/page`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...data, sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async submitKYC() {
        return api(`mango/kyc/submit`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

}
