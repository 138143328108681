
import { useState } from 'react'
import PaperHeader from 'components/PaperHeader'
import Paper from 'components/Paper'
import Typography from 'components/Typography'
import Button from 'components/Button'
import FileDropzone from 'components/FileDropzone'

import { PageHeading } from 'assets/const'
import { useMatchMedia } from 'hooks/useMatchMedia'


const MangoAccountVerification = () => {
  const { isTablet } = useMatchMedia()
  const [files, setFiles] = useState<File[] | null>(null)
  const [fileErrors, setFileErrors] = useState<string[] | null>(null)


  const removePdfFile = (file: File) => {
    setFiles(files!.filter((pdfFile) => pdfFile !== file))
    setFileErrors(null)
  }

  const handleDocumentsUpload = () => {
    console.log(files)
  }

  return (
    <Paper>
      <PaperHeader text={PageHeading.AccountVerification} />
      <Typography variant='h5'>
        Please upload your documents. The file size must not exceed 10 MB. Format jpg, png, pdf.
      </Typography>

      <FileDropzone
        files={files}
        setFiles={setFiles}
        setFileErrors={setFileErrors}
        fileErrors={fileErrors}
        action={removePdfFile}
      />

      <Button disabled={!files?.length} onClick={handleDocumentsUpload} fullWidth={!isTablet}>
        Send
      </Button>
    </Paper>
  )
}

export default MangoAccountVerification
