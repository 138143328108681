// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MangoKyc_pdy10__BIUsa {\n  padding: 10px 0 10px 0px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Mango/Settings/MangoKyc.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".pdy10 {\n  padding: 10px 0 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdy10": "MangoKyc_pdy10__BIUsa"
};
export default ___CSS_LOADER_EXPORT___;
