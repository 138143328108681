// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Settings_headerContainer__GG-Ib {\n  display: flex;\n  justify-content: flex-start;\n}\n.Settings_headerContainer__GG-Ib div {\n  width: -moz-fit-content;\n  width: fit-content;\n}\n.Settings_headerContainer__GG-Ib .Settings_secondaryHeader__JFOf9 {\n  margin-left: 8px;\n  margin-top: 6px;\n  color: var(--color-primary);\n}\n\n.Settings_buttonsContainer__VjxYQ {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  margin-top: 32px;\n  gap: 5px;\n  max-width: 220px;\n}\n@media (max-width: 425px) {\n  .Settings_buttonsContainer__VjxYQ {\n    max-width: 100%;\n  }\n  .Settings_buttonsContainer__VjxYQ button {\n    max-width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/Mango/Settings/Settings.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,2BAAA;AADF;AAEE;EACE,uBAAA;EAAA,kBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,2BAAA;AAAJ;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,QAAA;EACA,gBAAA;AADF;AAEE;EARF;IASI,eAAA;EACF;EAAE;IACE,eAAA;EAEJ;AACF","sourcesContent":["@use 'src/styles/mixin' as mixin;\n\n.headerContainer {\n  display: flex;\n  justify-content: flex-start;\n  div {\n    width: fit-content;\n  }\n  .secondaryHeader {\n    margin-left: 8px;\n    margin-top: 6px;\n    color: var(--color-primary);\n  }\n}\n\n.buttonsContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  margin-top: 32px;\n  gap: 5px;\n  max-width: 220px;\n  @media (max-width: 425px) {\n    max-width: 100%;\n    & button {\n      max-width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "Settings_headerContainer__GG-Ib",
	"secondaryHeader": "Settings_secondaryHeader__JFOf9",
	"buttonsContainer": "Settings_buttonsContainer__VjxYQ"
};
export default ___CSS_LOADER_EXPORT___;
