// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Success_content__DlSi8 {\n  text-align: center;\n  max-width: 516px;\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.Success_doneButton__oe0iX {\n  width: 180px;\n  margin-top: 24px;\n}\n\n.Success_infoContainer__2wKqv {\n  align-self: flex-start;\n  margin-top: 16px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Success/Success.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,gBAAA;AACF","sourcesContent":[".content {\n  text-align: center;\n  max-width: 516px;\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.doneButton {\n  width: 180px;\n  margin-top: 24px;\n}\n\n.infoContainer {\n  align-self: flex-start;\n  margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Success_content__DlSi8",
	"doneButton": "Success_doneButton__oe0iX",
	"infoContainer": "Success_infoContainer__2wKqv"
};
export default ___CSS_LOADER_EXPORT___;
