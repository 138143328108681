import { ComponentProps, ElementType } from 'react'

import s from './Button.module.scss'

type ButtonOwnProps<E extends ElementType> = {
  as?: E
  variant?: 'filled' | 'outlined' | 'text'
  size?: 'medium' | 'small'
  fullWidth?: boolean
  asDisabled?: boolean
}

type ButtonProps<E extends ElementType> = ButtonOwnProps<E> &
  Omit<ComponentProps<E>, keyof ButtonOwnProps<E>>

const defaultElement = 'button'

const Button = <E extends ElementType = typeof defaultElement>({
  as,
  children,
  variant = 'filled',
  size = 'medium',
  className,
  fullWidth,
  asDisabled,
  ...rest
}: ButtonProps<E>) => {
  const Component = as || defaultElement

  return (
    <Component
      className={`${s.button} ${s[variant]} ${s[size]} ${
        fullWidth ? s.fullWidth : ''
      } ${asDisabled ? s.disabled : ''} ${className ?? ''} `}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default Button
