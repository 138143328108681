import { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


interface CustomDatePickerProps {
  control: Control<any>
  name: string
  label: string
  required?: boolean
  fullWidth?: boolean
  errors?: any
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  control,
  name,
  label,
  required = false,
  fullWidth = true,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, name, onBlur } }) => (
          <DatePicker
            label={label}
            value={value ? dayjs(value) : null}
            onChange={(newValue: Dayjs | null) => {
              const dateValue = newValue ? newValue.toDate() : null
              onChange(dateValue)
            }}
            enableAccessibleFieldDOMStructure={true}
            slotProps={{
              textField: {
                required: required,
                fullWidth: fullWidth,
                size: 'small',
                sx: {
                  height: '50px',
                  '& .MuiPickersInputBase-root': {borderRadius: '8px',},
                  '& .MuiPickersInputBase-root.MuiPickersOutlinedInput-root:focus-visible': {border: ' 1px red solid', borderColor: 'red'},
                  mt: '6px',
                  fontSize: '12px',
                  '&.MuiPickersSectionList-root-MuiPickersInputBase-sectionsContainer-MuiPickersOutlinedInput-sectionsContainer': {padding: '4px 0'},
                  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink):not(.MuiInputLabel-focus)': {color: 'var(--color-light-gray-text)', fontWeight: '500'},
                  '& .MuiInputLabel-root.MuiInputLabel-shrink': {fontSize: '14px'},
                  '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-Focused': {fontSize: '14px', color: 'var(--color-primary)'},
                  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {top: '10%', fontSize: '14px'},
                  '& .MuiInputAdornment-root': { maxWidth: 'fit-content'},
                  '& .MuiInputAdornment-root svg': { color: 'var(--color-primary)'},
                    '& .MuiDateCalendar-root.Mui-selected.MuiButtonBase-root': {
                      backgroundColor: 'red',  // Цвет фона для выбранного дня
                    },
                }
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;