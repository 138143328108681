import { KeycloakConfig } from 'keycloak-js'
import { unstable_batchedUpdates } from 'react-dom'
import ReactDOM from 'react-dom/client'

import ErrorApp from 'ErrorApp'
import StyledToastContainer from 'components/StyledToastContainer'
import SseProvider from 'providers/SseProvider'
import SwrConfigProvider from 'providers/SwrProvider'
import App from './App'

import AuthService from 'services/auth.service'
import { ConfigurationService } from 'services/configuration.service'
import { useAppStore } from 'store/useAppStore'
import { Configuration } from 'types/configuration'

import { AUTH_CLIENT_ID } from 'assets/const'
import './index.css'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const setConfiguration = (configuration: Configuration) => {
  unstable_batchedUpdates(() => {
    useAppStore.getState().setConfiguration(configuration)
  })
}
const renderErrorApp = () =>
  root.render(
    <>
      <StyledToastContainer />
      <SwrConfigProvider>
        <ErrorApp />
      </SwrConfigProvider>
    </>
  )

const renderApp = () =>
  root.render(
    <>
      <StyledToastContainer />
      <SwrConfigProvider>
        <SseProvider>
          <App />
        </SseProvider>
      </SwrConfigProvider>
    </>
  )

const initApp = async () => {
  const configuration = await ConfigurationService.getConfiguration()

  if (!configuration) {
    renderErrorApp()
    return
  }

  setConfiguration(configuration)

  const clientId = AUTH_CLIENT_ID + configuration.sso.clientSuffix

  const keycloakConfig: KeycloakConfig = { ...configuration.sso, clientId }

  AuthService.initKeycloak(renderApp, keycloakConfig)
}

initApp()
reportWebVitals()
