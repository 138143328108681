import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import Modal from 'components/Modal'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Preloader from 'components/Preloader'
import StripeCreateForm from 'components/StripeCreateForm/StripeCreateForm'
import StripeEdit from 'components/StripeEdit/StripeEdit'

import { AppRoute, MessageType, PageHeading } from 'assets/const'
import StripeProfileForm from 'components/StripeProfileForm/StripeProfileForm'
import { useUserProfile } from 'hooks/data/useUserProfile'
import { usePath } from 'hooks/usePath'
import { useSseStore } from 'store/useSseStore'

const StripeProfile = () => {
  const { data: userData, isLoading, mutate: updateUser } = useUserProfile()
  const [isUpdatingSse, setIsUpdatingSse] = useState<boolean>(false)
  const [stripeType, setStripeType] = useState<'CREATE' | 'EDIT' | null>(null)
  const errorPath = usePath(`/${AppRoute.Error}`)

  const {
    stripeAccountLink,
    connectedAccount,
    sseErrorMessages,
    clearErrorMessage,
  } = useSseStore()

  const errorMessage = sseErrorMessages
    .find((msg) => msg.type === MessageType.Account)
    ?.message.split(';')[0]

  const accountId =
    connectedAccount?.connectedAccountId ||
    userData?.businessSettings.paymentSettings.stripe.connectedAccountId

  const submitHandler = () => {
    accountId ? setStripeType('EDIT') : setStripeType('CREATE')
  }

  const cancelHandler = () => {
    setStripeType(null)
  }

  useEffect(() => {
    if (!userData) return
    setIsUpdatingSse(false)
    setStripeType(null)
    updateUser()
  }, [connectedAccount])

  useEffect(() => {
    if (!errorMessage) return
    setIsUpdatingSse(false)
  }, [errorMessage])

  if (!userData && !isLoading) {
    return <Navigate to={errorPath} />
  }

  if (isLoading || !userData) {
    return (
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <Preloader />
      </div>
    )
  }

  return (
    <Paper textAlign='center'>
      <PaperHeader text={PageHeading.Profile} />
      {!stripeType && (
        <StripeProfileForm
          isCreated={!!accountId}
          userData={userData}
          onStripeClick={submitHandler}
        />
      )}
      {stripeType === 'CREATE' && (
        <StripeCreateForm
          userData={userData}
          setIsUpdatingSse={setIsUpdatingSse}
          isUpdatingSse={isUpdatingSse}
          onCancelClick={cancelHandler}
        />
      )}
      {stripeType === 'EDIT' && accountId && (
        <StripeEdit
          stripeAccountLink={stripeAccountLink}
          accountId={accountId}
          onCancelClick={cancelHandler}
        />
      )}

      {isUpdatingSse && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
          }}
        >
          <Preloader />
        </div>
      )}

      <Modal
        message={errorMessage ?? ''}
        isOpen={!!errorMessage}
        onClose={() => clearErrorMessage(MessageType.Account)}
      />
    </Paper>
  )
}

export default StripeProfile
