// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_headerWrapper__Y0JL0 {\n  width: 100vw;\n  background: var(--color-secondary-bg);\n  -webkit-backdrop-filter: blur(7px);\n          backdrop-filter: blur(7px);\n}\n.Header_headerWrapper__Y0JL0 .Header_headerContent__prDjQ {\n  display: flex;\n  min-height: 75px;\n  padding: 0 24px 0 46px;\n  margin: 0 auto;\n  align-items: center;\n}\n@media only screen and (max-width: 768px) {\n  .Header_headerWrapper__Y0JL0 .Header_headerContent__prDjQ {\n    padding: 0 16px;\n  }\n}\n@media only screen and (min-width: 769px) and (max-width: 1024px) {\n  .Header_headerWrapper__Y0JL0 .Header_headerContent__prDjQ {\n    padding: 0 20px;\n  }\n}\n.Header_headerWrapper__Y0JL0 .Header_headerContent__prDjQ .Header_logoWrapper__xg59m {\n  display: flex;\n  align-items: center;\n  gap: 3px;\n}\n.Header_headerWrapper__Y0JL0 .Header_user__vOhFY {\n  margin-left: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,qCAAA;EACA,kCAAA;UAAA,0BAAA;AADF;AAGE;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,mBAAA;AADJ;AAGI;EAPF;IAQI,eAAA;EAAJ;AACF;AAEI;EAXF;IAYI,eAAA;EACJ;AACF;AACI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACN;AAGE;EACE,iBAAA;AADJ","sourcesContent":["@use 'src/styles/mixin' as mixin;\n\n.headerWrapper {\n  width: 100vw;\n  background: var(--color-secondary-bg);\n  backdrop-filter: blur(7px);\n\n  .headerContent {\n    display: flex;\n    min-height: 75px;\n    padding: 0 24px 0 46px;\n    margin: 0 auto;\n    align-items: center;\n\n    @media only screen and (max-width: 768px) {\n      padding: 0 16px;\n    }\n\n    @media only screen and (min-width: 769px) and (max-width: 1024px) {\n      padding: 0 20px;\n    }\n\n    .logoWrapper {\n      display: flex;\n      align-items: center;\n      gap: 3px;\n    }\n  }\n\n  .user {\n    margin-left: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": "Header_headerWrapper__Y0JL0",
	"headerContent": "Header_headerContent__prDjQ",
	"logoWrapper": "Header_logoWrapper__xg59m",
	"user": "Header_user__vOhFY"
};
export default ___CSS_LOADER_EXPORT___;
