// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackSectionLayout_wrapper__gDyaP {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.BackSectionLayout_backSection__HghAn {\n  margin-bottom: 24px;\n}\n@media (max-width: 639px) {\n  .BackSectionLayout_backSection__HghAn {\n    margin-bottom: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/layouts/BackSectionLayout/BackSectionLayout.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,mBAAA;AADF;AAGE;EAHF;IAII,mBAAA;EAAF;AACF","sourcesContent":["@use 'src/styles/mixin' as mixin;\n\n.wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.backSection {\n  margin-bottom: 24px;\n\n  @media (max-width: 639px) {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "BackSectionLayout_wrapper__gDyaP",
	"backSection": "BackSectionLayout_backSection__HghAn"
};
export default ___CSS_LOADER_EXPORT___;
