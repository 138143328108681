import { InputHTMLAttributes } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import s from './TextField.module.scss'

interface InputProps {
  size?: 'medium' | 'small'
  label?: string
  error?: string
  register?: UseFormRegisterReturn<any>
  wrapperClassName?: string
}

type Props = InputProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, keyof InputProps>

const TextField = ({
  size = 'medium',
  error,
  label,
  className,
  placeholder,
  register,
  wrapperClassName,
  ...rest
}: Props) => {
  return (
    <div className={`${s.textField} ${s[size]} ${wrapperClassName ?? ''}`}>
      <input
        {...register}
        {...rest}
        className={`${s.input} ${className ?? ''} `}
        placeholder={'   '}
      />
      <span className={`${s.labelPlaceholder} ${error ? s.labelError : ''}`}>
        {label}
      </span>
      <fieldset className={`${s.fieldset} ${error ? s.withError : ''}`}>
        <legend>
          <span className={s.labelFieldset}>{label}</span>
        </legend>
      </fieldset>
      {error && <span className={s.error}>{error}</span>}
    </div>
  )
}

export default TextField
