import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import PaymentList from 'components/PaymentList'

import { AppRoute, PageHeading } from 'assets/const'

import { useInvoice } from 'hooks/data/useInvoice'
import { usePath } from 'hooks/usePath'
import BackSectionLayout from 'layouts/BackSectionLayout'

const PaymentSelection = () => {
  const rootPath = usePath(AppRoute.Root)
  const { isLoading, isValidating, isNewStatus } = useInvoice()
  return (
    <BackSectionLayout text='Back to payment information' path={rootPath}>
      <Paper textAlign='center'>
        {(isLoading || isValidating || isNewStatus) && (
          <>
            <PaperHeader text={PageHeading.PaymentSelect} />
            <PaymentList />
          </>
        )}
      </Paper>
    </BackSectionLayout>
  )
}

export default PaymentSelection
