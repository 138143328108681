import { ReactComponent as StripeLogo} from 'assets/svg/Stripe.svg'
import { ReactComponent as BtcLogo } from 'assets/svg/BtcLogo.svg'
import { ReactComponent as EthLogo } from 'assets/svg/EthLogo.svg'
import { ReactComponent as CWLogo } from 'assets/svg/CWLogoIcon.svg'
import { ReactComponent as MangoLogo } from 'assets/svg/MangoLogo.svg'
import { validateUrl } from 'utils/validate-url'


export const baseApiUrl = validateUrl(process.env.REACT_APP_API_URL)
export const gravatarUrl =
  validateUrl(process.env.REACT_APP_GRAVATAR_BASE_URI) ||
  'https://www.gravatar.com/avatar/'

export const AUTH_CLIENT_ID = 'cw-gateway-ui'

export const Params = {
  Token: 'token',
  ReturnUrl: 'returnUrl',
  RedirectStatus: 'redirect_status',
}

export const PROGRESS_BAR_VISIBLE = true

export const AppRoute = {
  StripePayment: 'stripe-payment',
  CryptoPayment: 'crypto-payment',
  CW: 'CW',
  Mango: {
    Root: '/mango/',
    Payment: 'payment',
    DictionaryStep: 'dictionary-step',
    PaymentStatus: 'status',
    Params: {
      InvoiceId: 'invoiceId'
    }
  },
  Stripe: 'stripe',
  Profile: 'profile',
  Settings: 'settings',
  AccountVerification: 'account-verification',
  StripeCompleted: 'stripe-completed',
  PaymentSettings: 'payment',
  ExternalAccounts: {
    Bank: 'external-bank-accounts',
    Card: 'external-card-accounts',
  },
  PaymentSelection: 'payment-selection',
  Root: '/',
  Success: 'success',
  Error: 'error',
  Params: {
    PaymentId: 'paymentId',
  },
} as const

export const menuRoutes = {
  Payment:
    `${AppRoute.Settings}/${AppRoute.PaymentSettings}`
  ,
  StripeProfile: `${AppRoute.Settings}/${AppRoute.Stripe}/${AppRoute.Profile}`,
  StripeBankAccounts:
    `${AppRoute.Settings}/${AppRoute.Stripe}/${AppRoute.ExternalAccounts.Bank}`,
  MangoProfile: `${AppRoute.Settings}${AppRoute.Mango.Root}${AppRoute.Profile}`,
  MangoVerification:
    `${AppRoute.Settings}${AppRoute.Mango.Root}${AppRoute.AccountVerification}`
}

export const QUERIES = [
  '(min-width: 0px)',
  '(min-width: 640px)',
  '(min-width: 1024px)',
  '(min-width: 1280px)',
  '(min-width: 1440px)',
] as const

export const SCREENS = [
  'isMobile',
  'isTablet',
  'isLaptop',
  'isDesktop',
  'isBigScreen',
] as const

export const SwrKey = {
  Invoice: 'invoice',
  Payment: 'payment',
  User: 'user',
  Gravatar: 'gravatar',
  ExternalAccounts: {
    Bank: {
      Create: 'ExternalAccounts.Bank.Create',
      List: 'ExternalAccounts.Bank.List',
    },
    Card: {
      Create: 'ExternalAccounts.Card.Create',
      List: 'ExternalAccounts.Card.List',
    },
  },
  Mango: {
    GetDict: 'Mango.GetDict',
    CreateMangoCard: 'Mango.CreateMangoCard',
    MangoPaymentInfo: 'Mango.MangoPaymentInfo',
  }
} as const

export const PageHeading = {
  PaymentInfo: 'Payment information',
  PaymentSelect: 'Payment selection',
  StripePayment: 'Stripe payment ',
  Success: 'Successfully completed',
  Error: 'Something went wrong',
  Profile: 'Stripe profile',
  AccountVerification: 'Account verification',
  ExternalAccounts: {
    Bank: 'External bank accounts',
    Card: 'External card accounts',
  },
  Settings: 'Payment settings',
} as const

export const PaymentSystemCode = {
  STRIPE: 'STRIPE',
  MANGO: 'MANGO',
  BTC: 'BTC',
  ETH: 'ETH',
} as const

export const CryptoLink = {
  BTC: 'bitcoin',
  ETH: 'eth',
  STRIPE: 'stripe',
  MANGO: 'mango',
} as const

export const Logo = {
  STRIPE: <StripeLogo/>,
  MANGO: <MangoLogo/>,
  BTC: <BtcLogo/>,
  ETH: <EthLogo/>,
  CW: <CWLogo color='var(--color-primary)'/>,
}

export const MessageType = {
  Country: 'country',
  Account: 'account',
  Link: 'account_link',
  PaymentSettings: 'payment_settings',
  Operation: 'operation',
  ExternalAccount: {
    Bank: 'external_bank_account',
    Card: 'external_card_account',
  },
  Invoice: 'invoice',
  CreateMangoPayer: 'createNaturalPayerUser',
  CreateMangoCard: 'createCardRegistration',
  ExecuteDirectMangoCardPayIn: 'executeDirectCardPayment'
} as const

export const ProgressBarTitle = {
  AwaitingPayment: 'Awaiting payment...',
  ExpiredPayment: 'Payment expired',
  AwaitingLink: 'Link lifetime...',
  ExpiredLink: 'Link expired',
}

export const InvoiceStatus = {
  New: 'new',
  Paid: 'paid',
  Cancelled: 'cancelled',
  Processing: 'processing',
}

export const StripeRedirectStatus = {
  Succeeded: 'succeeded',
}
