// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar_avatarContainer__5QA2Y {\n  position: relative;\n}\n.Avatar_avatarContainer__5QA2Y .Avatar_textAvatar__0TXJd {\n  display: flex;\n  width: 23px;\n  height: 23px;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  color: var(--color-contrast-text);\n  background-color: var(--color-gray);\n  font-weight: 300;\n  line-height: 1.43;\n  letter-spacing: -1;\n}\n.Avatar_avatarContainer__5QA2Y .Avatar_avatar__qbz0B {\n  border-radius: 50%;\n}\n.Avatar_avatarContainer__5QA2Y .Avatar_avatar__qbz0B.Avatar_hide__yJy\\+u {\n  position: absolute;\n  opacity: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iCAAA;EACA,mCAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,UAAA;AAAN","sourcesContent":[".avatarContainer {\n  position: relative;\n\n  .textAvatar {\n    display: flex;\n    width: 23px;\n    height: 23px;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    color: var(--color-contrast-text);\n    background-color: var(--color-gray);\n    font-weight: 300;\n    line-height: 1.43;\n    letter-spacing: -1;\n  }\n\n  .avatar {\n    border-radius: 50%;\n\n    &.hide {\n      position: absolute;\n      opacity: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": "Avatar_avatarContainer__5QA2Y",
	"textAvatar": "Avatar_textAvatar__0TXJd",
	"avatar": "Avatar_avatar__qbz0B",
	"hide": "Avatar_hide__yJy+u"
};
export default ___CSS_LOADER_EXPORT___;
