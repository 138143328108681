import {useMemo, useState} from 'react'
import PaperHeader from 'components/PaperHeader'
import Paper from 'components/Paper'
import Typography from 'components/Typography'
import Button from 'components/Button'
import FileDropzone from 'components/FileDropzone'

import { PageHeading } from 'assets/const'
import { useMatchMedia } from 'hooks/useMatchMedia'
import {useCreateKYC} from '../../../hooks/data/useMangoApi';
import {useUserProfile} from '../../../hooks/data/useUserProfile';
import {UserMangoStatusKYCText} from '../../../types/mango';
import {MangoService} from '../../../services/mango.service';
import s from './MangoKyc.module.scss'
import {useSseStore} from '../../../store/useSseStore';

const uploadDocsCount = 4;

const MangoKyc = () => {
    const { isTablet } = useMatchMedia()
    const [files, setFiles] = useState<File[]>([])
    const [pageNum, setPageNum] = useState<number>(1)
    const [fileErrors, setFileErrors] = useState<string[] | null>(null)
    const {mutate: startVerificationProcess} = useCreateKYC()
    const {mangoKycInfo} = useSseStore()
    const { data: profile, isLoading: isProfileLoading } = useUserProfile()
    const mangoKycStatus = profile?.businessSettings?.paymentSettings?.mango?.kycStatus

    const canShowUploadButton = files.length < uploadDocsCount

    const removePdfFile = (file: File) => {
        setFiles(files!.filter((pdfFile) => pdfFile !== file))
        setFileErrors(null)
        setPageNum(pageNum-1)
    }

    const uploadFiles = (file: File[] | null) => {
        if (file && mangoKycInfo?.mangoKycDocumentId) {
            const reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onloadend = () => {
                const base64String = reader.result as string;
                MangoService.pageKYC({
                    mangoKycDocumentId: mangoKycInfo.mangoKycDocumentId,
                    file: base64String,
                    pageNum: pageNum
                })
            };

            console.log('uploadFiles', file, files, pageNum)
            setFiles(file)
            setPageNum(pageNum+1)
        }
    }

    const handleDocumentsUpload = () => {
        MangoService.submitKYC(
            // {
            //     mangoKycDocumentId: mangoKycInfo.mangoKycDocumentId,
            //     files,
            // }
        )
        console.log(files)
    }

    return (
        <Paper>
            <PaperHeader text={PageHeading.AccountVerification} />

            <Typography variant='h3' className={s.pdy10}>
                {`${profile?.firstName} ${profile?.lastName}`}
            </Typography>
            {!!mangoKycStatus && <div className={s.pdy10}> Status: <span>{UserMangoStatusKYCText[mangoKycStatus]}</span></div>}

            { mangoKycInfo?.status !== 'CREATED' && <div className={s.pdy10}>
                    <Button disabled={false} onClick={startVerificationProcess} fullWidth={!isTablet}>
                        Start verification process
                    </Button>
                </div>
            }

            {mangoKycInfo?.status === 'CREATED' && <>
                <Typography variant='h5'>
                    Please upload your documents. The file size must not exceed 10 MB. Format jpg, png, pdf.
                </Typography>

                <FileDropzone
                    files={files}
                    setFiles={uploadFiles}
                    setFileErrors={setFileErrors}
                    fileErrors={fileErrors}
                    action={removePdfFile}
                    multiple={false}
                    showUploadButton={canShowUploadButton}
                />

                <Button disabled={!files?.length} onClick={handleDocumentsUpload} fullWidth={!isTablet}>
                    Send
                </Button>
            </>}
        </Paper>
    )
}

export default MangoKyc
