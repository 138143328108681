import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Preloader from 'components/Preloader'
import Button from 'components/Button'
import MangoProfileInfo from 'components/MangoProfileInfo'
import CreateMangoProfileModal from 'components/CreateMangoProfileModal'
import EditMangoIndividualProfileModal from 'components/EditMangoIndividualProfileModal'
import EditMangoCompanyProfileModal from 'components/EditMangoCompanyProfileModal'
import Typography from 'components/Typography'

import { AppRoute, PageHeading } from 'assets/const'
import { useUserProfile } from 'hooks/data/useUserProfile'
import { usePath } from 'hooks/usePath'

import s from './Settings.module.scss'


const mockIsAccountExist = true
const mockIsIndividual = false

const MangoProfile = () => {
  const { data: userData, isLoading } = useUserProfile()
  const [modal, setModal] = useState<'CREATE' | 'EDIT' | null>(null)
  const errorPath = usePath(`/${AppRoute.Error}`)

  if (!userData && !isLoading) {
    return <Navigate to={errorPath} />
  }

  if (isLoading || !userData) {
    return (
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <Preloader />
      </div>
    )
  }

  return (
    <Paper textAlign='center'>
      <div className={s.headerContainer}>
        <PaperHeader text={PageHeading.MangoProfile} />
        {mockIsAccountExist &&
          <Typography variant='h4' className={s.secondaryHeader}>
            {mockIsIndividual ? '(Individual)' : '(Company)'}
          </Typography>
        }
      </div>
      {mockIsAccountExist && <MangoProfileInfo userData={userData}/>}

      <div className={s.buttonsContainer}>
        <Button onClick={() => setModal(mockIsAccountExist ? 'EDIT' : 'CREATE')} type='button' fullWidth>
          {mockIsAccountExist ? 'Edit Mango Account' : 'Create Account'}
        </Button>
      </div>

      {modal === 'CREATE' && <CreateMangoProfileModal onClose={() => setModal(null)}/> }
      {modal === 'EDIT' && mockIsIndividual && <EditMangoIndividualProfileModal onClose={() => setModal(null)}/> }
      {modal === 'EDIT' && !mockIsIndividual && <EditMangoCompanyProfileModal onClose={() => setModal(null)}/> }
    </Paper>
  )
}

export default MangoProfile
