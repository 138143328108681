import { CryptoSettings, StripeSettings } from 'types/payment-settings'
import {
  Country,
  StripeAccountLink,
  StripeConnectedAccount,
} from 'types/user-profile'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { BankAccount, CardExternalAccount } from '../types/external-account'
import {MangoCardResponse, UserMangoStatus} from '../types/mango';

interface SseState {
  countries: Country[] | undefined
  setCountries: (payload: Country[]) => void
  connectedAccount: StripeConnectedAccount | undefined
  setConnectedAccount: (payload: StripeConnectedAccount | undefined) => void
  stripeAccountLink: StripeAccountLink | undefined
  setStripeAccountLink: (payload: StripeAccountLink | undefined) => void
  sseErrorMessages: { message: string; type: string }[]
  setSseErrorMessages: (payload: { message: string; type: string }) => void
  cryptoSettings: CryptoSettings | undefined
  setCryptoSettings: (payload: CryptoSettings | undefined) => void
  stripeSettings: StripeSettings | undefined
  setStripeSettings: (payload: StripeSettings | undefined) => void
  clearErrorMessage: (payload: string) => void
  bankAccounts: BankAccount[]
  setBankAccounts: (payload: { list: BankAccount[] }) => void
  cardAccounts: CardExternalAccount[]
  setCardAccounts: (payload: { list: CardExternalAccount[] }) => void
  isPendingCancellation: boolean
  setIsPendingCancellation: (payload: boolean) => void
  mangoUserStatus: UserMangoStatus
  setMangoUserStatus: (payload: UserMangoStatus) => void
  createMangoCardResponse: MangoCardResponse | null,
  setCreateMangoCardResponse: (payload: MangoCardResponse) => void,
}

export const useSseStore = create<SseState>()(
  devtools((set, get: () => SseState) => ({
    countries: undefined,
    setCountries: (payload) => set({ countries: payload }),
    connectedAccount: undefined,
    setConnectedAccount: (payload) => set({ connectedAccount: payload }),
    stripeAccountLink: undefined,
    setStripeAccountLink: (payload) => set({ stripeAccountLink: payload }),
    sseErrorMessages: [],
    setSseErrorMessages: (payload) => {
      const messageIndex = get().sseErrorMessages.findIndex(
        (err) => err.type === payload.type
      )
      if (messageIndex >= 0) {
        set({
          sseErrorMessages: get().sseErrorMessages.map((err) =>
            err.type === payload.type ? payload : err
          ),
        })
      } else {
        set({ sseErrorMessages: [...get().sseErrorMessages, payload] })
      }
    },
    clearErrorMessage: (payload) =>
      set({
        sseErrorMessages: get().sseErrorMessages.filter(
          (msg) => msg.type !== payload
        ),
      }),
    cryptoSettings: undefined,
    setCryptoSettings: (payload) => set({ cryptoSettings: payload }),
    stripeSettings: undefined,
    setStripeSettings: (payload) => set({ stripeSettings: payload }),

    //external area
    bankAccounts: [],
    setBankAccounts: (payload) => set({ bankAccounts: payload.list }),
    cardAccounts: [],
    setCardAccounts: (payload) => set({ cardAccounts: payload.list }),
    isPendingCancellation: false,
    setIsPendingCancellation: (payload) =>
      set({ isPendingCancellation: payload }),
    mangoUserStatus: UserMangoStatus.uncreated,
    setMangoUserStatus: (payload) => set({ mangoUserStatus: payload }),
    createMangoCardResponse: null,
    setCreateMangoCardResponse: (payload:MangoCardResponse) => set({ createMangoCardResponse: payload }),
  }))
)
