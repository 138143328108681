import { useEffect, useState } from 'react'

import Typography from 'components/Typography'
import Paper from 'components/Paper'

import { useNow } from 'hooks/useNow'
import { formatTime, getTimeStump } from 'utils/time'

import s from './LinearProgressBar.module.scss'


interface Props {
  timeout: number
  updateInterval?: number
  updateTransition?: number
  created?: number
  awaitingTitle: string
  expiredTitle: string
  onExpired?: (value: boolean) => void
}

const LinearProgressBar = ({
  awaitingTitle,
  expiredTitle,
  timeout,
  updateInterval = 1000,
  updateTransition = 1000,
  created,
  onExpired,
}: Props) => {
  const [startAt, setStartAt] = useState<number | null>(created ?? null)
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const now = useNow(updateInterval, !!startAt, (now) => {
    if (startAt && timeout - (now - startAt) < updateInterval) {
      setStartAt(null)
      setIsExpired(true)
      if (onExpired) {
        onExpired(true)
      }
    }
  })

  const msToExpired = startAt ? timeout - (now - startAt) : 0
  const leftPosition = msToExpired ? -(msToExpired * 100) / timeout : -100
  useEffect(() => {
    if (startAt) return
    setStartAt(getTimeStump())
  }, [])

  return (
    <div className={s.container}>
      <div className={s.progressBar}>
        <Typography className={s.title} style={{ fontWeight: '500' }}>
          {!isExpired ? awaitingTitle : expiredTitle}
        </Typography>

        <div
          className={`${s.emptyProgress} ${
            isExpired ? s.emptyExpired : s.emptyInProgress
          }`}
        >
          <div
            className={`${s.fillProgress} ${
              isExpired ? s.fillExpired : s.fillInProgress
            }`}
            style={{
              left:
                isExpired || (startAt && msToExpired < updateInterval)
                  ? 0
                  : leftPosition + '%',
              transition: updateTransition + 'ms',
            }}
          />
        </div>
      </div>
      <Typography className={s.percentage} style={{ fontWeight: '500' }}>
        {formatTime(msToExpired)}
      </Typography>
    </div>
  )
}

export default LinearProgressBar
