import {SelectOption} from '../../src/components/CustomInputSelect';
import {CheckoutSdkOptions} from '@mangopay/checkout-sdk/types/checkout-sdk-elements-core/checkout-sdk-options';

export type MangoDictionary = {
    clientId: 'string',
    cardTypeList: SelectOption[],
    currencyList: SelectOption[]
}

export enum UserMangoStatus {
    created = 'created',
    pending = 'pending',
    uncreated = 'uncreated'
}

export type MangoCardRequest = {
    mangoUserId: string,
    currency: string,
    cardType: string
}

export type PayinMangoCardRequest = {
    invoiceId:         string;
    cardType:          string;
    cardId:            string;
    acceptHeader:      string;
    javaEnabled:       boolean;
    javascriptEnabled: boolean;
    language:          string;
    colorDepth:        number;
    screenHeight:      number;
    screenWidth:       number;
    timeZoneOffset:    number;
    userAgent:         string;
    secureModeReturnURL: string;
}

export interface MangoCardResponse {
    accessKey: string;
    cardRegistrationId: string;
    cardRegistrationUrl: string;
    cardType: string;
    currency: string;
    preregistrationData: string;
    status: string;
    userId: string;
}

export enum SdkStatuses {
    NOT_READY = 'NOT_READY',
    READY = 'READY',
}

export interface SdkOptionsData {
    status: SdkStatuses;
    message?: string;
    data: CheckoutSdkOptions | null
}

export interface ReadySdkOptionsData extends SdkOptionsData {
    status: SdkStatuses.READY;
    data: CheckoutSdkOptions; // data не может быть null
}

export function IsReadySdkOptionsData(options: SdkOptionsData): options is ReadySdkOptionsData {
    return options.status === SdkStatuses.READY && options.data !== null;
}