// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StyledLink_styledLink__dVyED {\n  max-width: var(--width-btn-max);\n  border-radius: var(--radius-primary);\n}\n.StyledLink_styledLink__dVyED.StyledLink_fullWidth__KqTmp {\n  width: 100%;\n}\n.StyledLink_styledLink__dVyED:focus {\n  outline: 2px solid var(--color-primary-dark);\n}\n.StyledLink_styledLink__dVyED:active {\n  transform: scale(0.96);\n  outline: none;\n}", "",{"version":3,"sources":["webpack://./src/components/StyledLink/StyledLink.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,oCAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,4CAAA;AAAJ;AAEE;EACE,sBAAA;EACA,aAAA;AAAJ","sourcesContent":[".styledLink {\n  max-width: var(--width-btn-max);\n  border-radius: var(--radius-primary);\n\n  &.fullWidth {\n    width: 100%;\n  }\n\n  &:focus {\n    outline: 2px solid var(--color-primary-dark);\n  }\n  &:active {\n    transform: scale(0.96);\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styledLink": "StyledLink_styledLink__dVyED",
	"fullWidth": "StyledLink_fullWidth__KqTmp"
};
export default ___CSS_LOADER_EXPORT___;
