import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'

import Button from 'components/Button'
import CheckBox from 'components/CheckBox/CheckBox'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Preloader from 'components/Preloader'
import Typography from 'components/Typography'

import { AppRoute, PageHeading } from 'assets/const'
import { useUserProfile } from 'hooks/data/useUserProfile'
import { usePath } from 'hooks/usePath'
import { UserService } from 'services/user.service'
import { PaymentSettings } from 'types/payment-settings'

import {ReactComponent as StripeIcon} from 'assets/svg/Stripe.svg'
import {ReactComponent as BitcoinIcon} from 'assets/svg/Bitcoin.svg'
import {ReactComponent as EthereumIcon} from 'assets/svg/Ethereum.svg'

import s from './UserPaymentSettings.module.scss'


type FormFields = {
  stripe: boolean
  btc: boolean
  eth: boolean
}

const UserPaymentSettings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const errorPath = usePath(`/${AppRoute.Error}`)

  const {
    data: userData,
    isLoading,
    isValidating,
    mutate: updateUser,
  } = useUserProfile()

  const paymentSettings = userData?.businessSettings.paymentSettings

  const isStripeAccount = !!paymentSettings?.stripe.connectedAccountId

  const { register, handleSubmit } = useForm<FormFields>({
    values: {
      stripe: !!paymentSettings?.stripe.available,
      btc: !!paymentSettings?.btc.available,
      eth: !!paymentSettings?.eth.available,
    },
  })

  const onSubmit: SubmitHandler<FormFields> = (formData) => {
    setIsSubmitting(true)
    const { stripe, btc, eth } = formData
    const payload: PaymentSettings = {
      stripe: {
        connectedAccountId: paymentSettings?.stripe.connectedAccountId ?? '',
        customerId: paymentSettings?.stripe.customerId ?? '',
        available: stripe,
        canPayment: !!paymentSettings?.stripe.canPayment,
        canPayout: !!paymentSettings?.stripe.canPayout,
      },
      btc: {
        address: paymentSettings?.btc.address ?? '',
        available: btc,
      },
      eth: {
        address: paymentSettings?.eth.address ?? '',
        available: eth,
      },
    }
    UserService.setUserPaymentSettingsSse(payload).then(() => {
      updateUser()
    })
    setIsSubmitting(false)
  }

  if (!userData && !isLoading) {
    return <Navigate to={errorPath} />
  }

  if (isLoading || !userData) {
    return (
      <div style={{ position: 'relative', minHeight: '550px' }}>
        <Preloader />
      </div>
    )
  }

  return (
    <Paper>
      <PaperHeader text={PageHeading.Settings} />
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Typography as='h4' variant='h4'>
          Enable receiving payments:
        </Typography>
        <div className={s.container}>
          {isStripeAccount && (
            <div className={s.system}>
              <div className={s.iconBox}>
                <StripeIcon/>
              </div>
              <span className={s.name}>Stripe</span>
              <CheckBox label='' register={register('stripe')} disabled={!isStripeAccount}/>
            </div>
          )}
          <div className={s.system}>
            <div className={s.iconBox}>
              <BitcoinIcon/>
            </div>
            <span className={s.name}>Bitcoin</span>
            <CheckBox label='' register={register('btc')} />
          </div>
          <div className={s.system}>
            <div className={s.iconBox}>
              <EthereumIcon/>
            </div>
            <span className={s.name}>Ethereum</span>
            <CheckBox label='' register={register('eth')} />
          </div>
        </div>

        <div className={s.buttonsContainer}>
          <Button
            disabled={isSubmitting || isValidating}
            type='submit'
            fullWidth
          >
            Save
          </Button>
        </div>
      </form>
      {(isSubmitting || isValidating) && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
          }}
        >
          <Preloader />
        </div>
      )}
    </Paper>
  )
}

export default UserPaymentSettings
