// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppModal_container__pbyQ8 {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.3);\n  z-index: 100;\n  overflow-y: auto;\n  padding: 30px 20px;\n}\n\n.AppModal_modal__HGyt9 {\n  position: relative;\n  padding: 24px;\n  border-radius: 8px;\n  background-color: var(--color-main-bg);\n  width: -moz-fit-content;\n  width: fit-content;\n  min-width: 200px;\n  display: flex;\n  flex-direction: column;\n  margin: 20px 0;\n}\n\n.AppModal_closeIcon__CTO2N {\n  align-self: flex-end;\n  cursor: pointer;\n}\n\n.AppModal_title__boKB7 {\n  align-self: center;\n}\n\n.AppModal_content__nlXJl {\n  margin-top: 24px;\n}\n\n.AppModal_button__PxS4m {\n  width: 180px;\n}", "",{"version":3,"sources":["webpack://./src/components/AppModal/AppModal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,oCAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,sCAAA;EACA,uBAAA;EAAA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,oBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.3);\n  z-index: 100;\n  overflow-y: auto;\n  padding: 30px 20px;\n}\n\n.modal {\n  position: relative;\n  padding: 24px;\n  border-radius: 8px;\n  background-color: var(--color-main-bg);\n  width: fit-content;\n  min-width: 200px;\n  display: flex;\n  flex-direction: column;\n  margin: 20px 0;\n}\n\n.closeIcon {\n  align-self: flex-end;\n  cursor: pointer;\n}\n\n.title {\n  align-self: center;\n}\n\n.content {\n  margin-top: 24px;\n}\n\n.button {\n  width: 180px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AppModal_container__pbyQ8",
	"modal": "AppModal_modal__HGyt9",
	"closeIcon": "AppModal_closeIcon__CTO2N",
	"title": "AppModal_title__boKB7",
	"content": "AppModal_content__nlXJl",
	"button": "AppModal_button__PxS4m"
};
export default ___CSS_LOADER_EXPORT___;
