import { ReactComponent as CircularLoaderIcon } from 'assets/svg/CircularLoader.svg'
import s from './CircularLoader.module.scss'

interface Props {
  size?: number
}

const CircularLoader = ({ size = 44 }: Props) => {
  return (
    <span
      className={s.container}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <CircularLoaderIcon />
    </span>
  )
}

export default CircularLoader
