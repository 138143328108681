// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmPopup_container__9Ibop {\n  display: inline-block;\n  max-width: 440px;\n}\n\n.ConfirmPopup_container__9Ibop .ConfirmPopup_text__RWwRz {\n  display: inline-block;\n  text-align: center;\n  line-height: 1.3;\n}\n\n.ConfirmPopup_buttonsRemoveBlock__WU75G {\n  display: flex;\n  flex-direction: row;\n  padding-top: 32px;\n  gap: 24px;\n}\n.ConfirmPopup_buttonsRemoveBlock__WU75G > button {\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/components/ConfirmPopup/ConfirmPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;AACF;AAAE;EACE,SAAA;AAEJ","sourcesContent":[".container {\n  display: inline-block;\n  max-width: 440px;\n}\n\n.container .text {\n  display: inline-block;\n  text-align: center;\n  line-height: 1.3;\n}\n\n.buttonsRemoveBlock {\n  display: flex;\n  flex-direction: row;\n  padding-top: 32px;\n  gap: 24px;\n  & > button {\n    flex: 1;\n  }\n  @media (max-width: 639px) {\n    // flex-direction: column;\n    // gap: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConfirmPopup_container__9Ibop",
	"text": "ConfirmPopup_text__RWwRz",
	"buttonsRemoveBlock": "ConfirmPopup_buttonsRemoveBlock__WU75G"
};
export default ___CSS_LOADER_EXPORT___;
