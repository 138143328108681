// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButton_backLink__HY0Fv {\n  display: inline-flex;\n  align-items: center;\n  gap: 8px;\n}\n.BackButton_backLink__HY0Fv .BackButton_text__t3NC0 {\n  font-size: 14px;\n  font-weight: 400;\n}\n.BackButton_backLink__HY0Fv:hover {\n  opacity: 0.7;\n}\n\n.BackButton_rotateArrow__UXHqn {\n  transform: rotate(180deg);\n}\n\nbutton.BackButton_backLink__HY0Fv {\n  text-decoration: none;\n  padding: 0;\n  outline: 0;\n  border: 0;\n  background-color: transparent;\n  cursor: pointer;\n}\nbutton.BackButton_backLink__HY0Fv:hover {\n  opacity: 0.7;\n}", "",{"version":3,"sources":["webpack://./src/components/BackButton/BackButton.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE,qBAAA;EACA,UAAA;EACA,UAAA;EACA,SAAA;EACA,6BAAA;EACA,eAAA;AADF;AAME;EACE,YAAA;AAJJ","sourcesContent":[".backLink {\n  display: inline-flex;\n  align-items: center;\n  gap: 8px;\n\n  .text {\n    font-size: 14px;\n    font-weight: 400;\n  }\n\n  &:hover {\n    opacity: 0.7;\n  }\n}\n\n.rotateArrow {\n  transform: rotate(180deg);\n}\n\nbutton.backLink {\n  text-decoration: none;\n  padding: 0;\n  outline: 0;\n  border: 0;\n  background-color: transparent;\n  cursor: pointer;\n  // &:focus {\n  //   outline: 2px solid var(--color-primary-dark);\n  // }\n\n  &:hover {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backLink": "BackButton_backLink__HY0Fv",
	"text": "BackButton_text__t3NC0",
	"rotateArrow": "BackButton_rotateArrow__UXHqn"
};
export default ___CSS_LOADER_EXPORT___;
