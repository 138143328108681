import { UserProfile } from 'types/user-profile'
import { humanizeBoolean } from 'utils/text'

import s from 'components/StripeProfileForm/StripeProfileForm.module.scss'


interface Props {
  userData: UserProfile
}

const MangoProfileInfo = ({ userData }: Props) => {
  const stripeSettings = userData.businessSettings.paymentSettings.stripe

  const mockIsIndividual = true

  return (
      <div className={s.profileInfoWrapper}>
        <div className={s.profileItem}>
          <div className={s.label}>{mockIsIndividual ? 'First Name:' : 'Company Name:'}</div>
          <div className={s.value}>{userData.firstName}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>{mockIsIndividual ? 'Last Name:' : 'Type'}</div>
          <div className={s.value}>{userData.lastName}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Email:</div>
          <div className={s.value}>{userData.email}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>{mockIsIndividual ? 'Country of residence:' : 'Company number:'}</div>
          <div className={s.value}>{userData.username}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Mango available:</div>
          <div className={s.value}>
            {humanizeBoolean(stripeSettings.available)}
          </div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Mango profile id:</div>
          <div className={s.value}>{stripeSettings.connectedAccountId}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Payouts:</div>
          <div className={s.value}>
            {humanizeBoolean(stripeSettings.canPayout)}
          </div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Payments:</div>
          <div className={s.value}>
            {humanizeBoolean(stripeSettings.canPayment)}
          </div>
        </div>
    </div>
  )
}

export default MangoProfileInfo
