import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from 'components/Button'
import CustomSelect from 'components/CustomSelect'
import Preloader from 'components/Preloader'
import TextField from 'components/TextField'

import CustomInputSelect from 'components/CustomInputSelect'
import AppModal from 'components/AppModal'
import CheckBox from 'components/CheckBox/CheckBox'

import s from './CreateMangoProfileModal.module.scss'


interface Props {
  onClose: () => void
}

type SelectOption = {
  label: string
  value: string
}

type FormFields = {
  firstName: string
  lastName: string
  email: string
  country: SelectOption | undefined
  profileType: SelectOption | undefined
  companyName: string
  companyType: SelectOption | undefined
  agreement: boolean
}

const profileTypesOptions: SelectOption[] = [
  { label: 'Individual', value: 'individual' },
  { label: 'Company', value: 'company' },
]

const companyTypesOptions: SelectOption[] = [
  { label: 'Business', value: 'BUSINESS' },
  { label: 'Partnership', value: 'PARTNERSHIP' },
  { label: 'Organization', value: 'ORGANIZATION' },
  { label: 'Sole trader', value: 'SOLETRADER' },
]

const mockCountriesList = [
  {code: 'US', name: 'United States of America'},
  {code: 'IN', name: 'India'},
  {code: 'RU', name: 'Russia'},
  {code: 'CH', name: 'China'},
  {code: 'IR', name: 'Iran'},
]

const CreateMangoProfileModal = ({ onClose }: Props) => {
  const countries = mockCountriesList

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      firstName: '',
      lastName: '',
      email: '',
      country: undefined,
      profileType: undefined,
      companyName: '',
      companyType: undefined,
      agreement: false
    },
    mode: 'onBlur',
  })

  const [profileType, country, companyType, ] = watch(['profileType', 'country', 'companyType'])

  const countryOptions = useMemo(() => {
    if (!countries) return null
    return countries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [countries])

  const handleProfileTypeChange = (option: SelectOption | undefined) => {
    if (option?.value === 'individual') {
      resetField('companyName', {
        keepDirty: true,
        keepTouched: true,
      })
      resetField('companyType', {
        keepDirty: true,
        keepTouched: true,
      })
    }
    setValue('profileType', option)
  }

  const handleCompanyTypeChange = (option: SelectOption | undefined) => {
    setValue('companyType', option)
  }

  const handleCountryChange = (option: SelectOption | undefined) => {
    setValue('country', option)
  }

  const onSubmit: SubmitHandler<FormFields> = (formData) => {
    console.log(formData)
  }

  if (!countryOptions) return <Preloader />

  return (
    <AppModal title={'Create account'} onClose={onClose}>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          register={register('firstName', {
            required: 'This field is required',
          })}
          label='First name*'
          type='text'
          autoComplete='off'
          error={errors.firstName?.message}
        />
        <TextField
          register={register('lastName', {
            required: 'This field is required',
          })}
          label='Last name*'
          type='text'
          autoComplete='off'
          error={errors.lastName?.message}
        />
        <TextField
          register={register('email', {
            required: 'This field is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
          label='Email*'
          type='email'
          autoComplete='off'
          error={errors.email?.message}
        />
        <CustomInputSelect
          required
          options={countryOptions || []}
          onChange={handleCountryChange}
          value={country}
          label='Country'
        />
        <CustomSelect
          options={profileTypesOptions}
          onChange={handleProfileTypeChange}
          value={profileType}
          placeholder='Type (Individual/Company)*'
        />
        {profileType?.value === 'company' && (
          <>
            <TextField
              register={register('companyName', {
                required: 'This field is required',
              })}
              label='Company name*'
              type='text'
              autoComplete='off'
              error={errors.companyName?.message}
            />
            <CustomSelect
              options={companyTypesOptions}
              onChange={handleCompanyTypeChange}
              value={companyType}
              placeholder='Type (Individual/Company)*'
            />
          </>
        )}
        <CheckBox
          label='Accept Mangopay Terms & Conditions'
          register={register('agreement', { required: true })}
          required
        />

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={ !isValid }
            type='submit'
            fullWidth
          >
            Create
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default CreateMangoProfileModal
