// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footerWrapper__\\+ihpa {\n  background: var(--color-secondary-bg);\n  -webkit-backdrop-filter: blur(7px);\n          backdrop-filter: blur(7px);\n  width: 100vw;\n  height: 60px;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0 24px;\n}\n@media only screen and (max-width: 768px) {\n  .Footer_footerWrapper__\\+ihpa {\n    padding: 0 16px;\n    justify-content: space-between;\n    gap: 48px;\n  }\n}\n@media only screen and (min-width: 769px) and (max-width: 1024px) {\n  .Footer_footerWrapper__\\+ihpa {\n    padding: 0 20px;\n  }\n}\n\n.Footer_copyright__2WB24 {\n  color: var(--color-light-gray-text);\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;AACF;AACE;EAXF;IAYI,eAAA;IACA,8BAAA;IACA,SAAA;EAEF;AACF;AADE;EAhBF;IAiBI,eAAA;EAIF;AACF;;AAQA;EACE,mCAAA;EACA,mBAAA;AALF","sourcesContent":[".footerWrapper {\n  background: var(--color-secondary-bg);\n  backdrop-filter: blur(7px);\n  width: 100vw;\n  height: 60px;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0 24px;\n\n  @media only screen and (max-width: 768px) {\n    padding: 0 16px;\n    justify-content: space-between;\n    gap: 48px;\n  }\n  @media only screen and (min-width: 769px) and (max-width: 1024px) {\n    padding: 0 20px;\n  }\n}\n\n// .footerContent {\n//   display: flex;\n\n//   max-width: max-content;\n//   text-align: right;\n//   font-size: 12px;\n//   font-weight: 400;\n// }\n\n.copyright {\n  color: var(--color-light-gray-text);\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerWrapper": "Footer_footerWrapper__+ihpa",
	"copyright": "Footer_copyright__2WB24"
};
export default ___CSS_LOADER_EXPORT___;
