// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Preloader_preloader__LVy\\+h {\n  position: absolute;\n  display: flex;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  align-items: center;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/components/Preloader/Preloader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".preloader {\n  position: absolute;\n  display: flex;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preloader": "Preloader_preloader__LVy+h"
};
export default ___CSS_LOADER_EXPORT___;
