import { useEffect, useRef, useState } from 'react'
import s from './CustomSelect.module.scss'

type SelectOption = {
  label: string
  value: string
}

interface Props {
  size?: 'medium' | 'small'
  label?: string
  error?: string
  className?: string
  options: SelectOption[]
  value?: SelectOption
  maxDropdownHeight?: number
  onChange: (value: SelectOption | undefined) => void
  placeholder: string
}

const CustomSelect = ({
  size = 'medium',
  className,
  value,
  onChange,
  options,
  error,
  maxDropdownHeight = 200,
  placeholder,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const MARGIN_DROPDOWN = 5
  const LIST_ITEM_HEIGHT = 40

  const positionDropdown = () => {
    if (!dropdownRef.current || !containerRef.current) return
    const containerRect = containerRef.current.getBoundingClientRect()
    const containerBottom = containerRect.bottom
    const containerHeight = containerRect.height
    const dropdownHeight =
      options.length * LIST_ITEM_HEIGHT < maxDropdownHeight
        ? options.length * LIST_ITEM_HEIGHT
        : maxDropdownHeight

    if (
      window.innerHeight - containerBottom < dropdownHeight &&
      containerRect.top - dropdownHeight > MARGIN_DROPDOWN
    ) {
      dropdownRef.current.style.top = -dropdownHeight - MARGIN_DROPDOWN + 'px'
    } else {
      dropdownRef.current.style.top = containerHeight + MARGIN_DROPDOWN + 'px'
    }
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
    positionDropdown()
  }

  const selectOption = (option: SelectOption) => {
    if (option !== value) onChange(option)
  }

  const isOptionSelected = (option: SelectOption) => {
    return option === value
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return
      switch (e.code) {
        case 'Enter':
        case 'Space':
          setIsOpen((prev) => !prev)
          if (isOpen) selectOption(options[highlightedIndex])
          break
        case 'ArrowUp':
        case 'ArrowDown': {
          if (!isOpen) {
            setIsOpen(true)
            break
          }

          const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1)
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue)
          }
          break
        }
        case 'Escape':
          setIsOpen(false)
          break
      }

      if (
        e.key.length === 1 &&
        e.key.match(/[a-z]/i) &&
        dropdownRef.current?.children.length
      ) {
        const searchIndex = Array.from(dropdownRef.current?.children).findIndex(
          (el) => el.textContent?.toLowerCase().startsWith(e.key.toLowerCase())
        )
        if (searchIndex >= 0) {
          dropdownRef.current?.children[searchIndex].scrollIntoView()
          setHighlightedIndex(searchIndex)
        }
      }
    }

    containerRef.current?.addEventListener('keydown', handler)

    return () => {
      containerRef.current?.removeEventListener('keydown', handler)
    }
  }, [highlightedIndex, isOpen, options])

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0)
  }, [isOpen])

  return (
    <div
      ref={containerRef}
      onBlur={(e) => setIsOpen(false)}
      onClick={toggleDropdown}
      tabIndex={0}
      className={`${s.customSelect} ${value ? s.dirty : ''} ${
        isOpen ? s.active : ''
      } ${s[size]} ${className ?? ''}`}
      aria-haspopup='listbox'
      role='button'
    >
      <span className={`${s.labelPlaceholder} ${error ? s.labelError : ''}`}>
        {placeholder}
      </span>
      <fieldset className={`${s.fieldset} ${error ? s.withError : ''}`}>
        <legend>
          <span className={s.labelFieldset}>{placeholder}</span>
        </legend>
        <span className={s.value}>{value?.label}</span>
      </fieldset>
      <div className={`${s.caret}   ${isOpen ? s.open : ''}`}></div>
      <ul
        style={{ maxHeight: maxDropdownHeight + 'px' }}
        ref={dropdownRef}
        className={`${s.options} ${isOpen ? s.show : ''}`}
        role='listbox'
      >
        {options.map((option, index) => (
          <li
            className={`${s.option} ${
              isOptionSelected(option) ? s.selected : ''
            }
            
            ${index === highlightedIndex ? s.highlighted : ''}`}
            key={option.value}
            onMouseEnter={() => setHighlightedIndex(index)}
            onClick={(e) => {
              e.stopPropagation()
              selectOption(option)
              setIsOpen(false)
            }}
            aria-selected={isOptionSelected(option)}
            role='option'
          >
            <span>{option.label}</span>
          </li>
        ))}
      </ul>
      {error && <span className={s.error}>{error}</span>}
    </div>
  )
}

export default CustomSelect
