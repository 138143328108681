import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from 'components/Button'
import Preloader from 'components/Preloader'
import TextField from 'components/TextField'
import CustomInputSelect from 'components/CustomInputSelect'
import AppModal from 'components/AppModal'
import Typography from 'components/Typography'
import CustomDatePicker from 'components/DatePicker'

import s from './EditMangoIndividualProfileModal.module.scss'


interface Props {
  onClose: () => void
}

type SelectOption = {
  label: string
  value: string
}

type FormFields = {
  firstName: string
  lastName: string
  nationality: SelectOption | undefined
  countryOfResidence: SelectOption | undefined
  birthday: string
  email: string
  country: SelectOption | undefined
  city: string
  region: string
  postalCode: string
  addressLineFirst: string
  addressLineSecond: string
}

const mockNationalityOptions: SelectOption[] = [
  { label: 'Russian', value: 'russian' },
  { label: 'Spaniard', value: 'spaniard' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'German', value: 'german' },
]

const mockCountriesList = [
  {code: 'US', name: 'United States of America'},
  {code: 'IN', name: 'India'},
  {code: 'RU', name: 'Russia'},
  {code: 'CH', name: 'China'},
  {code: 'IR', name: 'Iran'},
]

const EditMangoIndividualProfileModal = ({ onClose }: Props) => {
  const countries = mockCountriesList

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      firstName: '',
      lastName: '',
      nationality: undefined,
      countryOfResidence: undefined,
      birthday: '',
      email: '',
      country: undefined,
      city: '',
      region: '',
      postalCode: '',
      addressLineFirst: '',
      addressLineSecond: '',
    },
    mode: 'onBlur',
  })

  const [countryOfResidence, country, nationality, ] = watch(['countryOfResidence', 'country', 'nationality'])

  const countryOptions = useMemo(() => {
    if (!countries) return null
    return countries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [countries])

  const handleCountryOfResidenceChange = (option: SelectOption | undefined) => {
    setValue('countryOfResidence', option)
  }

  const handleNationalityChange = (option: SelectOption | undefined) => {
    setValue('nationality', option)
  }

  const handleCountryChange = (option: SelectOption | undefined) => {
    setValue('country', option)
  }

  const onSubmit: SubmitHandler<FormFields> = (formData) => {
    console.log(formData)
  }

  if (!countryOptions) return <Preloader />

  return (
    <AppModal title={'Edit account'} onClose={onClose}>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={s.flexContainer}>
          <TextField
            register={register('firstName', {
              required: 'This field is required',
            })}
            label='First name*'
            autoComplete='off'
            error={errors.firstName?.message}
          />
          <TextField
            register={register('lastName', {
              required: 'This field is required',
            })}
            label='Last name*'
            autoComplete='off'
            error={errors.lastName?.message}
          />
        </div>
         <CustomInputSelect
          required
          options={mockNationalityOptions || []}
          onChange={handleNationalityChange}
          value={nationality}
          label='Nationality'
        />
        <CustomInputSelect
          required
          options={countryOptions || []}
          onChange={handleCountryOfResidenceChange}
          value={countryOfResidence}
          label='Country of residence'
        />

        <div className={s.flexContainer}>
          <CustomDatePicker
            control={control}
            name='birthday'
            label='Birthday*'
            errors={errors}
          />
          <TextField
            register={register('email', {
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
            label='Email*'
            type='email'
            autoComplete='off'
            error={errors.email?.message}
          />
        </div>

        <div>
          <Typography variant='h4' className={s.subTitle}>Address</Typography>
          <div className={s.form}>
            <CustomInputSelect
              options={countryOptions || []}
              onChange={handleCountryChange}
              value={country}
              label='Country'
            />
            <div className={s.flexContainer}>
              <TextField
                register={register('city')}
                label='City'
              />
              <TextField
                register={register('region')}
                label='Region'
              />
            </div>
            <TextField
              register={register('postalCode')}
              label='Postal code'
            />
            <TextField
              register={register('addressLineFirst')}
              label='Adress line 1'
            />
            <TextField
              register={register('addressLineSecond')}
              label='Adress line 2'
            />
          </div>
        </div>

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={ !isValid }
            type='submit'
            fullWidth
          >
            Save
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default EditMangoIndividualProfileModal
