import s from './RadioButton.module.scss'

interface Props
  extends Omit<React.HTMLProps<HTMLInputElement>, 'children' | 'size'> {
  size?: 'small' | 'medium'
}

const RadioButton = ({ className, size = 'medium', ...rest }: Props) => {
  return (
    <span className={`${s.box} ${s[size]} ${className ?? ''}`}>
      <input {...rest} type='radio' />
    </span>
  )
}

export default RadioButton
