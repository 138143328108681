import TextField from 'components/TextField'
import { useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import s from './CustomInputSelect.module.scss'

export type SelectOption = {
  label: string
  value: string
}

interface Props {
  size?: 'medium' | 'small'
  label: string
  required?: boolean
  className?: string
  options: SelectOption[]
  value?: SelectOption
  maxDropdownHeight?: number
  onChange: (value: SelectOption | undefined) => void
  disabled?: boolean
}

const CustomInputSelect = ({
  size = 'medium',
  className,
  value,
  onChange,
  options,
  required,
  maxDropdownHeight = 200,
  label,
  disabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState(value?.label ?? '')
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const [error, setError] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const MARGIN_DROPDOWN = 5
  const LIST_ITEM_HEIGHT = 40
  const actualLabel = `${label}${required ? '*' : ''}`
  const deferredValue = useDeferredValue(inputValue)
  const filteredOptions = useMemo(() => {
    if (value) {
      setInputValue(value.label)
      return options
    }
    return options.filter((item) =>
      item.label.toLowerCase().startsWith(deferredValue.toLowerCase())
    )
  }, [deferredValue, value, options])

  const positionDropdown = () => {
    if (!dropdownRef.current || !containerRef.current) return

    const currentOptionsLength = filteredOptions.length || 1
    const containerRect = containerRef.current.getBoundingClientRect()
    const containerBottom = containerRect.bottom
    const containerHeight = containerRect.height
    const dropdownHeight =
      currentOptionsLength * LIST_ITEM_HEIGHT < maxDropdownHeight
        ? currentOptionsLength * LIST_ITEM_HEIGHT
        : maxDropdownHeight
    if (
      window.innerHeight - containerBottom < dropdownHeight &&
      containerRect.top - dropdownHeight > MARGIN_DROPDOWN
    ) {
      dropdownRef.current.style.bottom =
        containerHeight + MARGIN_DROPDOWN + 'px'
      dropdownRef.current.style.top = ''
    } else {
      dropdownRef.current.style.bottom = ''
      dropdownRef.current.style.top = containerHeight + MARGIN_DROPDOWN + 'px'
    }
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const openDropdown = () => {
    setIsOpen(true)
  }

  const selectOption = (option: SelectOption) => {
    if (option !== value) onChange(option)
  }

  const clearOption = () => {
    onChange(undefined)
  }

  const handleSetValue = (option: SelectOption) => {
    selectOption(option)
    setInputValue(option.label)
    setError('')
  }

  const isOptionSelected = (option: SelectOption) => {
    return option === value
  }

  const handleCaretClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    toggleDropdown()
  }

  const handleErrors = () => {
    if (value) return
    if (required && !inputValue && !value) {
      setError('This field is required')
    }
    if (inputValue && !value) {
      setError(`Select ${label.toLowerCase()} from list`)
    }
  }

  useEffect(() => {
    positionDropdown()
  }, [isOpen])

  useEffect(() => {
    setHighlightedIndex(0)
    dropdownRef.current?.children[0].scrollIntoView()
  }, [filteredOptions, isOpen])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (!containerRef.current) return
      if (!e.target && !containerRef.current?.contains(e.target)) return
      switch (e.code) {
        case 'Enter':
          e.preventDefault()
          if (!filteredOptions.length) {
            break
          }
          toggleDropdown()
          if (isOpen) handleSetValue(filteredOptions[highlightedIndex])
          break
        case 'ArrowUp':
        case 'ArrowDown': {
          e.preventDefault()
          if (!filteredOptions.length || !isOpen) {
            break
          }
          const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1)
          if (newValue >= 0 && newValue < filteredOptions.length) {
            dropdownRef.current?.children[newValue].scrollIntoView()
            setHighlightedIndex(newValue)
          }
          break
        }
        case 'Escape':
          e.preventDefault()
          closeDropdown()
          break
      }
    }

    containerRef.current?.addEventListener('keydown', handler)

    return () => {
      containerRef.current?.removeEventListener('keydown', handler)
    }
  }, [highlightedIndex, isOpen, filteredOptions])

  return (
    <div
      onBlur={(evt) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(evt.relatedTarget)
        ) {
          closeDropdown()
          handleErrors()
        }
      }}
      ref={containerRef}
      onClick={openDropdown}
      tabIndex={0}
      className={`${s.customSelect} ${value ? s.dirty : ''} ${
        isOpen ? s.active : ''
      } ${s[size]} ${className ?? ''}`}
      aria-haspopup='listbox'
    >
      <TextField
        error={error}
        wrapperClassName={s.wrapperTextField}
        className={s.textField}
        label={actualLabel}
        disabled={disabled}
        value={inputValue}
        onChange={(e) => {
          clearOption()
          setInputValue(e.target.value)
          if (!isOpen) {
            openDropdown()
          }
        }}
      />
      <div
        onClick={handleCaretClick}
        className={`${s.caret}   ${isOpen ? s.open : ''}`}
        role='button'
      ></div>
      <ul
        style={{ maxHeight: maxDropdownHeight + 'px' }}
        ref={dropdownRef}
        className={`${s.options} ${isOpen ? s.show : ''}`}
        role='listbox'
        tabIndex={-1}
      >
        {!filteredOptions.length && (
          <li className={s.option}>
            <span className={s.noFound}>{`${label} is no found`}</span>
          </li>
        )}
        {filteredOptions.map((option, index) => (
          <li
            className={`${s.option} ${
              isOptionSelected(option) ? s.selected : ''
            }

            ${index === highlightedIndex ? s.highlighted : ''}`}
            key={option.value}
            onMouseEnter={() => setHighlightedIndex(index)}
            onClick={(e) => {
              e.stopPropagation()
              handleSetValue(option)
              closeDropdown()
            }}
            aria-selected={isOptionSelected(option)}
            role='option'
          >
            <span>{option.label}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CustomInputSelect
