import useSWR from 'swr/immutable'
import {SwrKey} from '../../assets/const';
import {MangoDictionary} from '../../types/mango';
import {MangoService} from '../../services/mango.service';

export const useGetMangoDict = () => {
    return useSWR<MangoDictionary, Error>(
        SwrKey.Mango.GetDict,
        () => MangoService.getDict(),
        { revalidateOnMount: true, revalidateOnFocus: false }
    )
}

export const useCreateMangoCard = (payload:any) => {
    return useSWR<any, Error>(
        payload ? SwrKey.Mango.CreateMangoCard : null,
        () => MangoService.createMangoCard(payload),
        { revalidateOnMount: true, revalidateOnFocus: false }
    )
}

export const useMangoPaymentInfo = (invoiceId: string | undefined) => {
    return useSWR<any, Error>(
        SwrKey.Mango.MangoPaymentInfo,
        () => invoiceId ? MangoService.getMangoPaymentInfo(invoiceId) : null,
        { revalidateOnMount: true, revalidateOnFocus: false, refreshInterval: 30000 }
    )
}