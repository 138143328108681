import React, {useState} from 'react'

import Button from 'components/Button'
import RadioButton from 'components/RadioButton'
import SkeletonLoader from 'components/SkeletonLoader'
import Typography from 'components/Typography'

import {AppRoute, Logo, PaymentSystemCode} from 'assets/const'
import {useInvoice} from 'hooks/data/useInvoice'
import {usePath} from 'hooks/usePath'

import {useUserProfile} from '../../hooks/data/useUserProfile';
import {MangoService} from '../../services/mango.service';
import {useNavigate} from 'react-router-dom';
import {useSseStore} from '../../store/useSseStore';
import {UserMangoStatus} from '../../types/mango';
import Preloader from '../Preloader';

import s from './PaymentList.module.scss'


const getRouteName = (activeItem: string | null, state = null) => {
  switch(activeItem) {
    case PaymentSystemCode.STRIPE:
      return AppRoute.Root + AppRoute.StripePayment
    case PaymentSystemCode.MANGO:
      return AppRoute.Mango.Root + AppRoute.Mango.DictionaryStep

    default:
      return AppRoute.Root +`${AppRoute.CryptoPayment}/${activeItem}`
  }
}

const PaymentList = () => {
  const [activeItem, setActiveItem] = useState<null | string>(null)
  const { data, isLoading, isValidating } = useInvoice()
  const {mangoUserStatus, setMangoUserStatus} = useSseStore()

  const path = usePath(getRouteName(activeItem))
  const navigate = useNavigate()
  const { data: profile, isLoading: isProfileLoading } = useUserProfile()

  const handleChange = (code: string) => {
    setActiveItem(code)
  }

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    if (activeItem === PaymentSystemCode.MANGO) {
      switch (mangoUserStatus) {
        case UserMangoStatus.created:
          navigate(path)
          return;
        case UserMangoStatus.pending:
          navigate(path)
          return;
        case UserMangoStatus.uncreated:
          MangoService.createMangoPayer()
          return;
      }
    }
  }

  if (mangoUserStatus === UserMangoStatus.pending) {
    return <div style={{ position: 'relative', minHeight: '250px' }}>
      <Preloader />
      <div className={s.pendingBlock}><span>Process creating mango user. Please waiting</span></div>
    </div>
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={s.header}>
        <Typography variant='h5'>Choose payment system</Typography>
      </div>

      {isLoading || isValidating ? (
        <SkeletonLoader
          count={3}
          height={60}
          containerClassName={s.skeletonItem}
        />
      ) : (
        <ul className={s.list}>
          {data?.paymentSystems.map((item) => (
            <li
              key={item.code}
              className={s.card}
              onClick={() => handleChange(item.code)}
            >
              <div className={s.icon}>
                <img
                  // src={item.icon || Logo[item.code as keyof typeof Logo]}
                  height={40}
                  width={'auto'}
                  alt='payment icon'
                  style={{ objectFit: 'contain' }}
                />
              </div>

              <div className={s.name}>
                <Typography
                  style={{ fontWeight: 500, textTransform: 'capitalize' }}
                >
                  {item.name}
                </Typography>
              </div>

              <RadioButton
                name='payment'
                value={item.code}
                className={s.radio}
                checked={item.code === activeItem}
                onChange={()=>{}}
                aria-label={`payment ${item.name}`}
              />
            </li>
          ))}
        </ul>
      )}

      {isLoading ? (
        <div className={s.skeletonBtn}>
          <SkeletonLoader size='medium' />
        </div>
      ) : (
        <div className={s.buttonsContainer}>
            <Button
                type='submit'
                fullWidth asDisabled={activeItem === null}>
              Continue
            </Button>
        </div>
      )}
    </form>
  )
}

export default PaymentList
