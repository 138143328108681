// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomTabs_tabsContainer__HI6Gd {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.CustomTabs_tabsHeader__iXKhr {\n  display: flex;\n  border-bottom: 2px solid #eee;\n}\n\n.CustomTabs_tab__9aZ2k {\n  padding: 10px 20px;\n  cursor: pointer;\n  flex: 1 1;\n  font-size: 16px;\n  border: none;\n  background-color: transparent;\n  color: var(--color-gray);\n  outline: none;\n  border-bottom: 2px solid transparent;\n  transition: all 0.3s ease;\n}\n.CustomTabs_tab__9aZ2k.CustomTabs_active__k3iLA {\n  border-bottom: 2px solid var(--color-primary);\n  color: var(--color-primary);\n}\n\n.CustomTabs_tabContent__y747s {\n  padding: 20px;\n  font-size: 16px;\n  color: #333;\n}", "",{"version":3,"sources":["webpack://./src/components/CustomTabs/CustomTabs.module.scss"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AAHF;;AAMA;EACE,aAAA;EACA,6BAAA;AAHF;;AAMA;EACE,kBAAA;EACA,eAAA;EACA,SAAA;EAEA,eAAA;EACA,YAAA;EACA,6BAAA;EACA,wBAAA;EACA,aAAA;EACA,oCAAA;EACA,yBAAA;AAJF;AAME;EACE,6CAAA;EACA,2BAAA;AAJJ;;AAQA;EACE,aAAA;EACA,eAAA;EACA,WAAA;AALF","sourcesContent":["$secondary-color: #eee;\n$tab-height: 40px;\n$transition-duration: 0.3s;\n\n.tabsContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.tabsHeader {\n  display: flex;\n  border-bottom: 2px solid $secondary-color;\n}\n\n.tab {\n  padding: 10px 20px;\n  cursor: pointer;\n  flex: 1;\n\n  font-size: 16px;\n  border: none;\n  background-color: transparent;\n  color: var(--color-gray);\n  outline: none;\n  border-bottom: 2px solid transparent;\n  transition: all $transition-duration ease;\n\n  &.active {\n    border-bottom: 2px solid var(--color-primary);\n    color: var(--color-primary);\n  }\n}\n\n.tabContent {\n  padding: 20px;\n  font-size: 16px;\n  color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": "CustomTabs_tabsContainer__HI6Gd",
	"tabsHeader": "CustomTabs_tabsHeader__iXKhr",
	"tab": "CustomTabs_tab__9aZ2k",
	"active": "CustomTabs_active__k3iLA",
	"tabContent": "CustomTabs_tabContent__y747s"
};
export default ___CSS_LOADER_EXPORT___;
