// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvoiceInfo_row__rOKMu {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  padding: 8px 16px;\n  align-self: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/components/InvoiceInfo/InvoiceInfo.module.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AADJ","sourcesContent":["@use 'src/styles/mixin' as mixin;\n\n  .row {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    padding: 8px 16px;\n    align-self:flex-end;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "InvoiceInfo_row__rOKMu"
};
export default ___CSS_LOADER_EXPORT___;
