import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from 'components/Button'
import Preloader from 'components/Preloader'
import TextField from 'components/TextField'
import CustomInputSelect from 'components/CustomInputSelect'
import AppModal from 'components/AppModal'
import Typography from 'components/Typography'
import CustomDatePicker from 'components/DatePicker'
import CustomSelect from 'components/CustomSelect'
import CustomTabs from 'components/CustomTabs'

import s from './EditMangoCompanyProfileModal.module.scss'



interface Props {
  onClose: () => void
}

type SelectOption = {
  label: string
  value: string
}

type FormFields = {
  companyName: string
  companyType: SelectOption | undefined
  companyEmail: string
  companyNumber: string
  companyCountry: SelectOption | undefined
  companyCity: string
  companyRegion: string
  companyPostalCode: string
  companyAddressLineFirst: string
  companyAddressLineSecond: string

  firstName: string
  lastName: string
  nationality: SelectOption | undefined
  countryOfResidence: SelectOption | undefined
  birthday: string
  email: string
  country: SelectOption | undefined
  city: string
  region: string
  postalCode: string
  addressLineFirst: string
  addressLineSecond: string
}

const tabs = [
  { label: 'Company information', value: 0 },
  { label: 'Legal Representative', value: 1 },
];

const companyTypesOptions: SelectOption[] = [
  { label: 'Business', value: 'BUSINESS' },
  { label: 'Partnership', value: 'PARTNERSHIP' },
  { label: 'Organization', value: 'ORGANIZATION' },
  { label: 'Sole trader', value: 'SOLETRADER' },
]

const mockNationalityOptions: SelectOption[] = [
  { label: 'Russian', value: 'russian' },
  { label: 'Spaniard', value: 'spaniard' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'German', value: 'german' },
]

const mockCountriesList = [
  {code: 'US', name: 'United States of America'},
  {code: 'IN', name: 'India'},
  {code: 'RU', name: 'Russia'},
  {code: 'CH', name: 'China'},
  {code: 'IR', name: 'Iran'},
]

const EditMangoCompanyProfileModal = ({ onClose }: Props) => {
  const countries = mockCountriesList
  const [activeTab, setActiveTab] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      companyName: '',
      companyType: undefined,
      companyEmail: '',
      companyNumber: '',
      companyCountry: undefined,
      companyCity: '',
      companyRegion: '',
      companyPostalCode: '',
      companyAddressLineFirst: '',
      companyAddressLineSecond: '',

      firstName: '',
      lastName: '',
      nationality: undefined,
      countryOfResidence: undefined,
      birthday: '',
      email: '',
      country: undefined,
      city: '',
      region: '',
      postalCode: '',
      addressLineFirst: '',
      addressLineSecond: '',
    },
    mode: 'onBlur',
  })

  const [countryOfResidence, country, nationality, companyCountry, companyType ] = watch(
    ['countryOfResidence', 'country', 'nationality', 'companyCountry', 'companyType']
  )

  const countryOptions = useMemo(() => {
    if (!countries) return null
    return countries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [countries])

  const handleCountryTypeChange = (option: SelectOption | undefined) => {
    setValue('companyType', option)
  }

  const handleCountryOfCompanyChange = (option: SelectOption | undefined) => {
    setValue('companyCountry', option)
  }

  const handleCountryOfResidenceChange = (option: SelectOption | undefined) => {
    setValue('countryOfResidence', option)
  }

  const handleNationalityChange = (option: SelectOption | undefined) => {
    setValue('nationality', option)
  }

  const handleCountryChange = (option: SelectOption | undefined) => {
    setValue('country', option)
  }

  const onSubmit: SubmitHandler<FormFields> = (formData) => {
    console.log(formData)
  }

  if (!countryOptions) return <Preloader />

  return (
    <AppModal title={'Edit account'} onClose={onClose}>

      <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        {activeTab === 0 ?
        <>
          <TextField
            register={register('companyName', {
              required: 'This field is required',
            })}
            label='Company name*'
            type='text'
            autoComplete='off'
            error={errors.firstName?.message}
          />
          <CustomSelect
            placeholder='Type'
            options={companyTypesOptions || []}
            onChange={handleCountryTypeChange}
            value={companyType}
          />

          <div className={s.flexContainer}>
            <TextField
              register={register('companyEmail', {
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              label='Email*'
              type='companyEmail'
              autoComplete='off'
              error={errors.email?.message}
            />
            <TextField
              register={register('companyNumber', {
                required: 'This field is required',
              })}
              label='Company number*'
              autoComplete='off'
              error={errors.email?.message}
            />
          </div>

          <div>
            <Typography variant='h4' className={s.subTitle}>Company address</Typography>
            <div className={s.form}>
              <CustomInputSelect
                options={countryOptions || []}
                onChange={handleCountryOfCompanyChange}
                value={companyCountry}
                label='Country'
              />
              <div className={s.flexContainer}>
                <TextField
                  register={register('companyCity')}
                  label='City'
                />
                <TextField
                  register={register('companyRegion')}
                  label='Region'
                />
              </div>
              <TextField
                register={register('companyPostalCode')}
                label='Postal code'
              />
              <TextField
                register={register('companyAddressLineFirst')}
                label='Adress line 1'
              />
              <TextField
                register={register('companyAddressLineSecond')}
                label='Adress line 2'
              />
            </div>
          </div>
        </>
        :
        <>
          <div className={s.flexContainer}>
            <TextField
              register={register('firstName', {
                required: 'This field is required',
              })}
              label='First name*'
              type='text'
              autoComplete='off'
              error={errors.firstName?.message}
            />
            <TextField
              register={register('lastName', {
                required: 'This field is required',
              })}
              label='Last name*'
              type='text'
              autoComplete='off'
              error={errors.lastName?.message}
            />
          </div>
          <CustomInputSelect
            required
            options={mockNationalityOptions || []}
            onChange={handleNationalityChange}
            value={nationality}
            label='Nationality'
          />
          <CustomInputSelect
            required
            options={countryOptions || []}
            onChange={handleCountryOfResidenceChange}
            value={countryOfResidence}
            label='Country of residence'
          />

          <div className={s.flexContainer}>
            <CustomDatePicker
              control={control}
              name='birthday'
              label='Birthday*'
              errors={errors}
            />
            <TextField
              register={register('email', {
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              label='Email*'
              type='email'
              autoComplete='off'
              error={errors.email?.message}
            />
          </div>

          <div>
            <Typography variant='h4' className={s.subTitle}>Address</Typography>
            <div className={s.form}>
              <CustomInputSelect
                options={countryOptions || []}
                onChange={handleCountryChange}
                value={country}
                label='Country'
              />
              <div className={s.flexContainer}>
                <TextField
                  register={register('city')}
                  label='City'
                />
                <TextField
                  register={register('region')}
                  label='Region'
                />
              </div>
              <TextField
                register={register('postalCode')}
                label='Postal code'
              />
              <TextField
                register={register('addressLineFirst')}
                label='Adress line 1'
              />
              <TextField
                register={register('addressLineSecond')}
                label='Adress line 2'
              />
            </div>
          </div>
        </>
        }

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={ !isValid }
            type='submit'
            fullWidth
          >
            Save
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default EditMangoCompanyProfileModal
