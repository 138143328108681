// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Paper_paperWrapper__QlkHR {\n  width: 100%;\n  max-width: 1056;\n  background-color: var(--color-white);\n  position: relative;\n  padding: 24px;\n  box-shadow: var(--box-shadow);\n}\n@media (max-width: 640px) {\n  .Paper_paperWrapper__QlkHR {\n    padding: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Paper/Paper.module.scss"],"names":[],"mappings":"AAAA;EAIE,WAAA;EACA,eAAA;EACA,oCAAA;EACA,kBAAA;EACA,aAAA;EACA,6BAAA;AAFF;AAGE;EAVF;IAWI,aAAA;EAAF;AACF","sourcesContent":[".paperWrapper {\n  // border: var(--color-primary-border) solid 1px;\n  // box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),\n  //   0 30px 60px -30px rgb(0 0 0 / 30%);\n  width: 100%;\n  max-width: 1056;\n  background-color: var(--color-white);\n  position: relative;\n  padding: 24px;\n  box-shadow: var(--box-shadow);\n  @media (max-width: 640px) {\n    padding: 16px;\n  }\n  // @media only screen and (max-width: 1024px) {\n  //   padding: 20px;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paperWrapper": "Paper_paperWrapper__QlkHR"
};
export default ___CSS_LOADER_EXPORT___;
