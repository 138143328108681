// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StripeEdit_buttonsContainer__UrR2k {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-top: 10px;\n  gap: 24px;\n}\n.StripeEdit_buttonsContainer__UrR2k button, .StripeEdit_buttonsContainer__UrR2k a {\n  width: 176px;\n}\n@media (max-width: 639px) {\n  .StripeEdit_buttonsContainer__UrR2k button, .StripeEdit_buttonsContainer__UrR2k a {\n    width: 100%;\n    max-width: 100%;\n  }\n}\n@media (max-width: 639px) {\n  .StripeEdit_buttonsContainer__UrR2k {\n    gap: 12px;\n  }\n}\n\n.StripeEdit_accountLink__ytvE2 {\n  margin-top: 32px;\n}\n\n.StripeEdit_preloader__svH8x {\n  position: relative;\n  min-height: 300px;\n}", "",{"version":3,"sources":["webpack://./src/components/StripeEdit/StripeEdit.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AACF;AAAE;EACE,YAAA;AAEJ;AADI;EAFF;IAGI,WAAA;IACA,eAAA;EAIJ;AACF;AAFE;EAbF;IAcI,SAAA;EAKF;AACF;;AAFA;EACE,gBAAA;AAKF;;AAFA;EACE,kBAAA;EACA,iBAAA;AAKF","sourcesContent":[".buttonsContainer {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-top: 10px;\n  gap: 24px;\n  & button, a {\n    width: 176px;\n    @media (max-width: 639px) {\n      width: 100%;\n      max-width: 100%;\n    }\n  }\n  @media (max-width: 639px) {\n    gap: 12px;\n  }\n}\n\n.accountLink {\n  margin-top: 32px;\n}\n\n.preloader {\n  position: relative;\n  min-height: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": "StripeEdit_buttonsContainer__UrR2k",
	"accountLink": "StripeEdit_accountLink__ytvE2",
	"preloader": "StripeEdit_preloader__svH8x"
};
export default ___CSS_LOADER_EXPORT___;
